import header from "../../../../content/character/salazar/salazar-header.png";
import token from "../../../../content/character/salazar/salazar-token.png";
import faust from "../../../../content/character/salazar/faust-green-small.png";
import vincent from "../../../../content/character/salazar/vincent-malfoy.png";
import * as React from 'react';
export default {
  header,
  token,
  faust,
  vincent,
  React
};
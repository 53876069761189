import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Power from "../../../../src/components/CharacterSheet/Power.js";
import exampleTrait from "../../../../content/rules/rules-players/example-trait.json";
import exampleTalent from "../../../../content/rules/rules-players/example-talent.json";
import exampleSpell from "../../../../content/rules/rules-players/example-spell.json";
import exampleSkill from "../../../../content/rules/rules-players/example-skill.json";
import exampleItem from "../../../../content/rules/rules-players/example-item.json";
import gold from "../../../../content/rules/rules-players/gold.json";
import * as React from 'react';
export default {
  Link,
  FontAwesomeIcon,
  Power,
  exampleTrait,
  exampleTalent,
  exampleSpell,
  exampleSkill,
  exampleItem,
  gold,
  React
};